import React from "react";
import { useState } from "react";
import { Divider, Hero, Kbd, Button, Tooltip, Table, Input } from "react-daisyui";
import { CsvToHtmlTable } from "react-csv-to-table";
import { readString, jsonToCSV } from "react-papaparse";
import { toast } from "react-toastify";
//Icons
import { TbFileImport } from "react-icons/tb";
import { SiGooglesheets } from "react-icons/si";
//Components
import FilePickerWrapper from "../components/FilePickerWrapper";
//utils

// TODO Export without first line (columns headers)
// TODO Export without difficulty column

const DIFFICULTIES = [1, 2, 3, 4, 5];
const COLUMN_NAMES = ["question", "Bonne réponse", "Mauvaise réponse 1", "Mauvaise réponse 2", "Mauvaise réponse 3", "difficulte (1-5)"]
const OUTPUT_CSV_HEADER = ["question","Bonne réponse","Mauvaise réponse 1","Mauvaise réponse 2","Mauvaise réponse 3"]
const DIFF_COLUMN_POSITION = "difficulte (1-5)";

const MainPage = (props) => {

  const [fileWrapperOpen, setFileWrapperOpen] = useState(0);
  const [csvTextData, setCsvTextData] = useState("");
  const [jsonData, setJsonData] = useState("");
  const [csvFileName, setCsvFileName] = useState("");
  const [fileUploadErrors, setFileUploadErrors] = useState(null);
  const [outputNumberOfQuestions, setOutputNumberOfQuestions] = useState(30);

  const readFile = async (file) => {
    if (file.name) setCsvFileName(file.name);
    const reader = new FileReader();
    reader.onload = async (e) => {
      const text = e.target.result;
      const pppJson = readString(text, {
        delimiter: ",",
        header: true,
        transformHeader: (header) => {
          return header.replace('incorrect_answer_', 'Mauvaise réponse ').replace('correct_answer', 'Bonne réponse')
        },
        skipEmptyLines: "greedy",
      })
      const pppCsv = jsonToCSV(pppJson)
      //console.log(pppJson)
      setCsvTextData(pppCsv);
      setJsonData(pppJson);
    };
    reader.readAsText(file);
  };

  // const convertCsvToJson = (text) => {
  //   const jsonValues = readString(text);
  //   if (jsonValues.errors && jsonValues.errors.length > 0) {
  //     toast.error(
  //       "Erreur lors du parsing, assurez vous d'uploader un fichier .csv valide"
  //     );
  //     setCsvTextData("");
  //     setCsvFileName("");
  //   }
  //   return jsonValues.data;    <div className="flex w-full component-preview p-4 items-center justify-center gap-2 font-sans">
  // };

  const deleteUnwanted = (question) => {
    const finalQuestion = question
    console.log("tamere", finalQuestion)
    Object.keys(finalQuestion).forEach(key => {
      if(!OUTPUT_CSV_HEADER.includes(key)) {
        delete finalQuestion[key]
      }
    });
    return finalQuestion
  }

  const onDownload = () => {
    const potentialQuestions = new Array();
    for (let index = 0; index < DIFFICULTIES.length; index++) {
      const difficulty = index + 1
      //Merge 5 arrays of questions, each for 1 to 5 difficulties
      potentialQuestions.push(selectQuestionsByDifficulty(difficulty))
    }
    console.log("potentialQuestions", potentialQuestions)
    //Spaghetti code FTW, maybe refactor that one day, made it in a rush
    const finalQuestionsForDifficulty = new Map();
    potentialQuestions.forEach((questionsForDifficulty, index) => {
      const difficulty = index + 1
      finalQuestionsForDifficulty.set(difficulty, [])
      let tries = 0;
      while (tries < 150) {
        const question =
          questionsForDifficulty[
            Math.floor(Math.random() * questionsForDifficulty.length)
          ];
        const arr = finalQuestionsForDifficulty.get(difficulty)
        if (arr && Array.isArray(arr) && !arr.includes(question)) {
          arr.push(question)
          finalQuestionsForDifficulty.set(difficulty, arr)
        }
        if(finalQuestionsForDifficulty.get(difficulty).length === Math.floor(outputNumberOfQuestions / DIFFICULTIES.length)) {
          break;
        }
        tries++;
      }
    });
    const tempQuestions = []
    const jsonQuestionForPapaparse = []
    const questionMapToArray = Array.from(finalQuestionsForDifficulty.values())
    for (let i = 0; i < questionMapToArray.length; i++) {
      const questionListForDifficulty = questionMapToArray[i];
      for (let j = 0; j < questionListForDifficulty.length; j++) {
        const question = questionListForDifficulty[j];
        tempQuestions.push(question)
      }
    }
    for (let i = 0; i < tempQuestions.length; i++) {
      const question = deleteUnwanted(tempQuestions[i]);
      console.log('question', question)
      const finalObj = {}
      for (let i = 0; i < Object.values(question).length; i++) {
        const qElement = Object.values(question)[i];
        finalObj[OUTPUT_CSV_HEADER[i]] = qElement
      }
      jsonQuestionForPapaparse.push(finalObj)
    }
    console.log("jsonQuestionForPapaparse",jsonQuestionForPapaparse)
    const toCsv = jsonToCSV(jsonQuestionForPapaparse, {header: false})
    console.log('---------------------------');
    console.log('Results:', toCsv);
    console.log('---------------------------');
    const file = new Blob([toCsv], {type: 'text/plain;charset=utf-8'})
    const url = URL.createObjectURL(file)
    const link = document.createElement("a");
    link.download = "questions-script.csv";
    link.href = url;
    link.click();
  };



  const selectQuestionsByDifficulty = (difficulty) => {
    return jsonData.data.filter((it) => {
      if (it[0] === "question") return false; //It means it selected the first line...
      const diff = parseInt(it[DIFF_COLUMN_POSITION]);
      if (
        Number.isNaN(diff) ||
        diff < DIFFICULTIES[0] ||
        diff > DIFFICULTIES[DIFFICULTIES.length - 1]
      ) {
        return false;
      }
      if (diff === difficulty) return true;
      return false;
    });
  };

  return (
    <div className="p-6">
      <div
        id="main-container"
        className="bg-opacity-60 bg-slate-600 rounded-lg p-4"
      >
        <Hero>
          <Hero.Content className="text-center text-3xl">
            <p>Quizkit Export Tool</p>
          </Hero.Content>
        </Hero>
        <p className="text-lg">
          Importez un fichier au format{" "}
          <Kbd className="bg-accent" size="md">
            .csv
          </Kbd>{" "}
          conforme pour télécharger vos questions
        </p>
        <div id="import-choice" className="flex justify-center mt-12">
          <div className="flex flex-col lg:flex-row">
            <FilePickerWrapper
              fileWrapperOpen={fileWrapperOpen}
              onFileSelected={(files) => readFile(files)}
              onError={(errors) => setFileUploadErrors(errors)}
            >
              <Button
                color="secondary"
                className="grid flex-grow min-h-16 h-20 w-64 cardrounded-box place-items-center"
                onClick={() => setFileWrapperOpen(fileWrapperOpen + 1)}
              >
                <TbFileImport className="text-4xl" />
                Fichier .CSV
              </Button>
            </FilePickerWrapper>
            <Divider className="my-6 lg:mx-6 w-full lg:w-1 rounded h-auto bg-slate-500"></Divider>
            <Tooltip message="Prochainement !">
              <Button
                color="secondary"
                disabled
                className="grid flex-grow min-h-16 h-20 w-64 cardrounded-box place-items-center "
              >
                <SiGooglesheets className="text-4xl text-gray-400" />
                <span className="text-gray-400">Google Sheets</span>
              </Button>
            </Tooltip>
          </div>
        </div>
        {/* PARAMETERS */}
        <div className="mt-12 flex flex-col justify-center items-center">
          <label className="mb-3">Nombre de questions</label>
          <Input type="number" color='primary'  value={outputNumberOfQuestions} onChange={e => {setOutputNumberOfQuestions(e.target.value)}} />
        </div>
        {/* DOWNLOAD */}
        <div className="mt-12 flex flex-col justify-center items-center">
          <p className="mb-6 text-lg">
            Le fichier sera exporté en <b>{outputNumberOfQuestions} questions aléatoires</b>, au moins {Math.floor(outputNumberOfQuestions / DIFFICULTIES.length)} pour
            chaque niveau de difficulté (1 à 5)
          </p>
          {
            csvTextData !== "" ? (
              <Button color="primary" size="lg" onClick={() => onDownload()}>
                Télécharger
              </Button>
          ) : null}
        </div>

        {csvFileName !== "" ? (
          <p className="text-left text-2xl font-bold mt-12">{csvFileName}</p>
        ) : null}
        <div className="scrollbar-thin scrollbar-track-base-100 scrollbar-thumb-primary scrollbar-thumb-rounded-full overflow-x-auto h-96 mt-4">
          {jsonData !== "" ? (
            <Table>
              <Table.Head>
                {jsonData?.meta?.fields.map((headerField,ind) => {
                  return <span key={ind}>{headerField}</span>
                })}
              </Table.Head>
              <Table.Body>
              {jsonData?.data?.map((line, lIndex) => {
                  return (
                    <Table.Row key={"line-"+lIndex}>
                      {COLUMN_NAMES.map((columnName, cIndex) => {
                        return <span key={"line-"+lIndex+"-val-"+cIndex}>{line[columnName]}</span>
                      })}
                    </Table.Row>
                  )
                })}
              </Table.Body>
            </Table>
            
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default MainPage;
/*
<CsvToHtmlTable
              data={csvTextData}
              csvDelimiter=','
              tableClassName="table table-zebra table-compact w-full"
            />
*/