import "./styles/App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//Screens
import MainPage from "./screens/MainPage";
import Copyright from "./components/Copyright";

function App() {
  return (
    <div className="App bg-slate-800 text-white h-max">
      <ToastContainer position="top-right" theme="dark" />
      <MainPage />
      <Copyright />
    </div>
  );
}

export default App;
