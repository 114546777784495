import React from "react";
import { useEffect } from "react";
import { useFilePicker } from "react-sage";

const FilePickerWrapper = (props) => {
  const { files, errors, onClick, HiddenFileInput } = useFilePicker({
    maxFileSize: 1,
  });

  useEffect(() => {
    onClick();
  }, [props.fileWrapperOpen]);

  useEffect(() => {
    if (files.length > 0) {
      // always select first one for the sake of this app
      props.onFileSelected(files[0]);
    }
  }, [files]);

  useEffect(() => {
    props.onError(errors);
  }, [errors]);

  return (
    <>
      <HiddenFileInput accept=".csv, .png" multiple={false} />
      {props.children}
    </>
  );
};

export default FilePickerWrapper;
