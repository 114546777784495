const Copyright = () => {
  return (
    <div className="p-4">
      Made with ❤️ by{" "}
      <a
        href="https://github.com/Merihel"
        target="_blank"
        className="decoration-accent decoration-2 underline-offset-4 underline transition-all hover:decoration-4"
      >
        Merihel
      </a>
    </div>
  );
};

export default Copyright;
